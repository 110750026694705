import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  DataReferenceConfig,
  DataReferencePipe,
  ViewComponent,
  ViewConfig,
  ViewSlotDirective,
  parseDataReference,
  viewComponentInputs,
  viewRegistry,
} from '@npm-libs/ng-templater';
import { FormConfig } from '@rcg/core/models';
import { RcgFormsModule } from '@rcg/forms';
import { Observable, combineLatest, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';

const name = 'form';
type Name = typeof name;

export type FormViewProps = {
  formId: DataReferenceConfig;
  formrecordId: DataReferenceConfig;
  formMode: DataReferenceConfig;
  formFields?: DataReferenceConfig;
  formOptions?: DataReferenceConfig;
  parentFormId?: DataReferenceConfig;
  prefillData?: DataReferenceConfig;
};

export type FormViewConfig = ViewConfig<Name, FormViewProps>;

@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  imports: [CommonModule, ViewSlotDirective, DataReferencePipe, RcgFormsModule],
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormViewComponent extends ViewComponent<Name, FormViewProps, FormViewConfig> implements OnInit {
  formConfig$?: Observable<FormConfig>;

  ngOnInit(): void {
    this.formConfig$ = combineLatest([
      parseDataReference(this.dataReferenceContext, this.config.props?.formId),
      parseDataReference(this.dataReferenceContext, this.config.props?.formrecordId),
      parseDataReference(this.dataReferenceContext, this.config.props?.formMode),
    ]).pipe(
      map(([formId, formRecordId, formMode]) => ({ formId, formRecordId, formMode })),
      filter((s) => !!s?.formId && !!s?.formRecordId && !!s.formMode),
      distinctUntilChanged((p, c) => p.formRecordId === c.formRecordId),
      map(
        (s) =>
          ({
            formId: s.formId! as number,
            formMode: s.formMode! as string,
            formrecordId: s.formRecordId as number,
          } as FormConfig),
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      }),
    );
  }
}

viewRegistry[name] = {
  component: FormViewComponent,
};
